import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { SignInFormValues } from '@m3ter-com/console-core/types';

const suite = create('signInForm', (data: Partial<SignInFormValues>) => {
  test(
    'email',
    i18next.t('forms:validations.users.emailAddressRequired'),
    () => {
      enforce(data.email).isNotEmpty();
    }
  );

  test(
    'email',
    i18next.t('forms:validations.users.emailAddressNoSpaces'),
    () => {
      enforce(data.email).matches(/^\S+$/);
    }
  );

  test(
    'password',
    i18next.t('forms:validations.users.passwordRequired'),
    () => {
      enforce(data.password).isNotEmpty();
    }
  );
});

export default suite;
