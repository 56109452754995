import React, { useCallback, useMemo } from 'react';

import {
  Alert,
  AlertDescription,
  AlertTitle,
  chakra,
  CloseButton,
  Icon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  InfoIcon,
} from 'lucide-react';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
type ColorScheme = 'blue' | 'orange' | 'green' | 'red';

export interface NotificationProps {
  id: string;
  title?: string;
  message: string;
  type?: NotificationType;
  onClose?: (id: string) => void;
}

const NOTIFICATION_TYPES: Record<
  NotificationType,
  { icon: React.ElementType; colorScheme: ColorScheme }
> = {
  info: { icon: InfoIcon, colorScheme: 'blue' },
  warning: { icon: AlertCircle, colorScheme: 'orange' },
  success: { icon: CheckCircle, colorScheme: 'green' },
  error: { icon: AlertTriangle, colorScheme: 'red' },
};

export const Notification: React.FC<NotificationProps> = ({
  id,
  title,
  message,
  type = 'info',
  onClose,
}) => {
  const onCloseClick = useCallback(() => {
    if (onClose) {
      onClose(id);
    }
  }, [onClose, id]);

  const { colorScheme, icon } = useMemo(() => NOTIFICATION_TYPES[type], [type]);
  const styles = useMultiStyleConfig('Notification', {
    colorScheme,
  });

  return (
    <Alert status={type} id={id} sx={styles.container}>
      <Icon sx={styles.icon} as={icon} />
      <chakra.div flex="1">
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{message}</AlertDescription>
      </chakra.div>
      {onClose && <CloseButton size="sm" onClick={onCloseClick} />}
    </Alert>
  );
};
