import React from 'react';

import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import {
  BlocksIcon,
  EllipsisIcon,
  HardDriveUploadIcon,
  SettingsIcon,
} from 'lucide-react';

import {
  BadgeIconButton,
  HeaderNavigationItem,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';

export const HeaderOrgMenu: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const isDataExportsEnabled = isFeatureEnabled(Feature.DataExports);

  return (
    <Menu placement="bottom-end">
      <HeaderNavigationItem>
        <MenuButton
          as={BadgeIconButton}
          aria-label={t('common:menu')}
          icon={<EllipsisIcon />}
          variant="header-nav"
        />
      </HeaderNavigationItem>
      <MenuList>
        <MenuItem as={NamedLink} name="settings">
          <Icon as={SettingsIcon} mr={2} /> {t('common:settings')}
        </MenuItem>
        <MenuItem as={NamedLink} name="integrations">
          <Icon as={BlocksIcon} mr={2} /> {t('common:integrations')}
        </MenuItem>
        {isDataExportsEnabled && (
          <MenuItem as={NamedLink} name="data">
            <Icon as={HardDriveUploadIcon} mr={2} /> {t('common:dataExports')}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
