import { v4 } from 'uuid';

import {
  IngestMeasurementDatum,
  IngestMeasurementDimension,
  Meter,
  MeterDataField,
  MeterDerivedField,
  MeterFieldCategory,
} from '@m3ter-com/m3ter-api';

import config from '@/config';

const numericalCategories = [
  MeterFieldCategory.MEASURE,
  MeterFieldCategory.COST,
  MeterFieldCategory.INCOME,
];

const aggregatableCategories = [
  ...numericalCategories,
  MeterFieldCategory.METADATA,
];

export const isCategoryNumeric = (category: MeterFieldCategory) =>
  numericalCategories.includes(category);

export const isFieldNumeric = (field: MeterDataField | MeterDerivedField) =>
  isCategoryNumeric(field.category);

export const isCategoryAggregatable = (category: MeterFieldCategory) =>
  aggregatableCategories.includes(category);

export const isFieldAggregatable = (
  field: MeterDataField | MeterDerivedField
) => isCategoryAggregatable(field.category);

export const isDerivedField = (
  field: MeterDataField | MeterDerivedField
): field is MeterDerivedField => 'calculation' in field;

export const generateSampleData = (
  meter: Meter,
  account: string = ''
): IngestMeasurementDatum => {
  const data: IngestMeasurementDatum = {
    uid: v4(),
    meter: meter.code,
    account,
    ts: new Date().toISOString(),
  };

  meter.dataFields.forEach((field) => {
    const key = field.category.toLowerCase() as IngestMeasurementDimension;
    if (data[key] === undefined) {
      data[key] = {};
    }
    data[key]![field.code] = isFieldNumeric(field) ? 0 : '';
  });

  return data;
};

export const getSubmissionUrl = (organizationId: string) =>
  `${config.ingestEndpoint}/organizations/${organizationId}/measurements`;
