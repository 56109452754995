import React, { Suspense, useEffect } from 'react';

import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@chakra-ui/react';

import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutMain,
  LoadingScreen,
} from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import {
  selectBootstrapFailureReason,
  selectOrgTimezone,
  changeOrg,
  selectIsBootstrapping,
} from '@/store/app/bootstrap/bootstrap';
import useOrg from '@/hooks/data/crud/useOrg';
import { TimezoneContextProvider } from '@/hooks/util/useDateFormatter';
import { ConsoleHeader } from '@/components/common/navigation/ConsoleHeader';
import { BootstrapFailureContent } from '@/components/features/organization/BootstrapFailureContent/BootstrapFailureContent';

const BoostrapSuccessContent: React.FC = () => {
  const orgTimezone = useSelector(selectOrgTimezone);

  return (
    <TimezoneContextProvider timeZone={orgTimezone}>
      <AppLayout data-testid="organization-route-content">
        <AppLayoutHeader>
          <ConsoleHeader />
        </AppLayoutHeader>
        <Suspense
          fallback={
            <AppLayoutMain
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner size="xl" />
            </AppLayoutMain>
          }
        >
          <Outlet />
        </Suspense>
      </AppLayout>
    </TimezoneContextProvider>
  );
};

export const OrganizationRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { orgId: urlOrgId } = useParams();
  const { currentOrgId: stateOrgId } = useOrg();
  const isAppBootstrapping = useSelector(selectIsBootstrapping);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason);

  useEffect(() => {
    if (!isAppBootstrapping && urlOrgId && urlOrgId !== stateOrgId) {
      dispatch(changeOrg(urlOrgId));
    }
  }, [dispatch, isAppBootstrapping, stateOrgId, urlOrgId]);

  if (isAppBootstrapping) {
    setAuthRedirectPath(pathname + search);
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  if (!stateOrgId) {
    return null;
  }

  return appBootstrapFailureReason ? (
    <BootstrapFailureContent />
  ) : (
    <BoostrapSuccessContent />
  );
};
