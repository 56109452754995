import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['container', 'icon'];

const notification: ComponentMultiStyleConfig = {
  parts,
  baseStyle: ({ colorScheme: c, ...props }) => ({
    container: {
      alignItems: 'start',
      width: 'auto',
      boxShadow: 'md',
      borderRadius: 'md',
      textAlign: 'left',
      pointerEvents: 'auto',
      bg: mode(`${c}.100`, `${c}.900`)(props),
    },
    icon: {
      color: mode(`${c}.500`, `${c}.200`)(props),
      flexShrink: 0,
      marginEnd: '3',
      w: '5',
      h: '6',
    },
  }),
};

export default notification;
