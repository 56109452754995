import { useCallback, useEffect, useState } from 'react';

import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { useMutation } from '@tanstack/react-query';

import {
  submitMeasurements as submitMeasurementsToApi,
  IngestRequestBody,
  DateTimeISOString,
  Id,
} from '@m3ter-com/m3ter-api';

import useOrg from '@/hooks/data/crud/useOrg';
import { extractErrorMessage } from '@/util/error';

export type ResponseType = 'success' | 'error';

export interface Submission {
  id: Id;
  body: IngestRequestBody;
  requestTimestamp: DateTimeISOString;
  response?: string;
  responseType?: ResponseType;
  responseTimestamp?: DateTimeISOString;
}

const useIngest = () => {
  const { currentOrgId: organizationId } = useOrg();
  const [submissions, setSubmissions] = useState<Array<Submission>>([]);

  const addSubmission = useCallback((body: IngestRequestBody) => {
    setSubmissions((currentSubmissions) => [
      {
        id: v4(),
        body: cloneDeep(body),
        requestTimestamp: new Date(Date.now()).toISOString(),
      },
      ...currentSubmissions,
    ]);
  }, []);

  const updateSubmission = useCallback(
    (response: string, responseType: ResponseType) => {
      setSubmissions((currentSubmissions) =>
        currentSubmissions.map((submission, index) =>
          index === 0
            ? {
                ...submission,
                responseType,
                response,
                responseTimestamp: new Date(Date.now()).toISOString(),
              }
            : submission
        )
      );
    },
    []
  );

  const resetSubmissions = useCallback(() => {
    setSubmissions([]);
  }, []);

  const { isPending: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: IngestRequestBody) =>
      submitMeasurementsToApi(organizationId, body),
    onSuccess: (submissionResponse) => {
      updateSubmission(JSON.stringify(submissionResponse), 'success');
    },
    onError: (error) => {
      updateSubmission(extractErrorMessage(error), 'error');
    },
  });

  const submitMeasurements = useCallback(
    (ingestRequestBody: IngestRequestBody) => {
      addSubmission(ingestRequestBody);
      submit(ingestRequestBody);
    },
    [addSubmission, submit]
  );

  useEffect(() => {
    return () => {
      resetSubmissions();
    };
  }, [resetSubmissions]);

  return {
    isSubmitting,
    submissions,
    submitMeasurements,
  };
};

export default useIngest;
