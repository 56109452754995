import React, { useCallback, useMemo, useState } from 'react';

import { useSteps } from '@chakra-ui/react';

import { FormStack, Stepper, Step } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Id,
  ChildBillingMode,
  DateString,
  type Plan,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import {
  Form,
  FormActions,
  FormField,
  FormRadioGroup,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import planCreateSchema from '@/validation/planCreate';
import accountPlanSchema from '@/validation/accountPlan';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import { AccountPlanDateFields } from '@/components/features/accounts/AccountPlanDateFields';
import { PlanFormFields } from '@/components/features/pricing/PlanFormFields';
import { AccountContractField } from '@/components/features/accounts/AccountContractField';

export interface AccountCustomPlanFormValues extends UnsavedEntity<Plan> {
  accountId: Id;
  billEpoch?: DateString;
  childBillingMode?: ChildBillingMode;
  contractId?: Id;
  endDate?: string;
  startDate: string;
}

export interface AccountCustomPlanFormProps
  extends BaseFormProps<AccountCustomPlanFormValues> {}

const defaultInitialValues: Partial<AccountCustomPlanFormValues> = {};

export const AccountCustomPlanForm: React.FC<AccountCustomPlanFormProps> = ({
  isSaving,
  onSave,
  onCancel,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  const { activeStep, goToNext, goToPrevious } = useSteps({ count: 2 });

  const [currentValues, setCurrentValues] =
    useState<DeepPartial<AccountCustomPlanFormValues>>(initialValues);

  const onPlanFormSubmit = useCallback(
    (data: DeepPartial<AccountCustomPlanFormValues>) => {
      setCurrentValues(data);
      goToNext();
    },
    [goToNext]
  );

  const childBillingModeOptions = useMemo(
    () =>
      Object.values(ChildBillingMode).map((value) => ({
        value,
        label: t(`features:account.childBillingMode.${value}`),
      })),
    [t]
  );

  return (
    <React.Fragment>
      <Stepper index={activeStep} mb={6}>
        <Step title={t('features:account.createPlan')} />
        <Step title={t('features:account.attachPlan')} />
      </Stepper>
      {activeStep === 0 && (
        <Form
          onSubmit={onPlanFormSubmit}
          initialValues={currentValues}
          validationSchema={planCreateSchema}
        >
          <PlanFormFields isEdit={false} includeCustomFields={false} />
          <FormActions
            cancelText={t('common:cancel')}
            submitText={t('common:next')}
            onCancel={onCancel}
          />
        </Form>
      )}
      {/* This form is also passed the plan data so all the combined data will be submitted in onSubmit */}
      {activeStep === 1 && (
        <Form
          onSubmit={onSave}
          initialValues={currentValues}
          validationSchema={accountPlanSchema}
        >
          <FormStack>
            <FormSection heading={t('forms:labels.planAttachmentSettings')}>
              <AccountPlanDateFields checkOverlap />
              <FormField
                stripTime
                name="billEpoch"
                label={t('forms:labels.billingCycleDate')}
                helpText={t('forms:helpText.accountPlanBillEpoch')}
                control={FormDatePicker}
              />
              <AccountContractField name="contractId" />
              <FormField
                stacked
                name="childBillingMode"
                label={t('forms:labels.accountChildBillingMode')}
                helpText={t('forms:helpText.childBillingMode')}
                control={FormRadioGroup}
                options={childBillingModeOptions}
              />
            </FormSection>
            <FormActions
              backText={t('common:back')}
              cancelText={t('common:cancel')}
              submitText={t('features:account.createPlanAndAttach')}
              isSaving={isSaving}
              onBack={goToPrevious}
              onCancel={onCancel}
            />
          </FormStack>
        </Form>
      )}
    </React.Fragment>
  );
};
