import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Feature {
  CompoundAggregations = 'compoundAggregations',
  DataExports = 'dataExports',
  IntegrationDestinations = 'destinations',
  Notifications = 'notifications',
  Reports = 'reports',
  Statements = 'statements',
  TransactionPricing = 'transactionPricing',
}

export interface FlagsState {
  featuresEnabled: Array<Feature>;
}

interface SetFeaturesEnabledPayload {
  features: Array<Feature>;
}
export type SetFeaturesEnabledAction = PayloadAction<SetFeaturesEnabledPayload>;

const name = 'features/flags';

const initialState: FlagsState = {
  featuresEnabled: [],
};

const flagsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFeaturesEnabled: {
      reducer: (state: FlagsState, action: SetFeaturesEnabledAction) => {
        state.featuresEnabled = action.payload.features;
      },
      prepare: (features: Array<Feature>) => ({
        payload: { features },
      }),
    },
    reset: () => initialState,
  },
});

// Actions

export const { setFeaturesEnabled, reset } = flagsSlice.actions;

// Selectors

const selectFlagsState = (state: { features: { flags: FlagsState } }) =>
  state.features.flags;

export const selectFeaturesEnabled = createSelector(
  selectFlagsState,
  (flagsState) => flagsState.featuresEnabled
);

export default flagsSlice.reducer;
