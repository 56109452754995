import React, { useEffect, useMemo, useRef } from 'react';

import { AlertProps } from '@chakra-ui/react';

import { Alert } from '@m3ter-com/ui-components';

import { AppError } from '@/types/errors';

import { extractError } from '@/util/error';

export interface ErrorAlertProps extends AlertProps {
  error: AppError | Error;
  title?: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  error,
  title,
  ...rest
}) => {
  const appError = useMemo(() => {
    return error instanceof Error ? extractError(error) : error;
  }, [error]);

  const errorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Always ensure the error is in the viewport.
    if (errorRef.current) {
      const rect = errorRef.current.getBoundingClientRect();

      if (rect.top < 0) {
        // Is above the viewport, scroll to top.
        errorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else if (rect.bottom > window.innerHeight) {
        // Is below the viewport, scroll to bottom.
        errorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }, []);

  return (
    <Alert
      ref={errorRef}
      status="error"
      alertTitle={title}
      p={2}
      {...rest}
      mt={2}
      mb={2}
    >
      {appError.message}
    </Alert>
  );
};
