import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  AccountPlan,
  create,
  DataType,
  DataTypeToEntity,
  del,
  Id,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import { AccountCustomPlanFormValues } from '@/components/features/accounts/AccountCustomPlanForm';
import { AccountCustomPlanGroupFormValues } from '@/components/features/accounts/AccountCustomPlanGroupForm';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import useOrg from '@/hooks/data/crud/useOrg';
import useQueryString from '@/hooks/navigation/useQueryString';
import { dataCreated } from '@/store/data/data';

export type CustomPlanDataType = DataType.Plan | DataType.PlanGroup;

type CustomPlanFormValues =
  | AccountCustomPlanFormValues
  | AccountCustomPlanGroupFormValues;

interface CustomPlanOrPlanGroupCreateResponse<DT extends CustomPlanDataType> {
  createdAccountPlan: AccountPlan;
  createdPlanOrPlanGroup: DataTypeToEntity[DT];
}

export const createCustomPlanOrPlanGroup = async <
  DT extends CustomPlanDataType,
  FormValues extends CustomPlanFormValues
>(
  dataType: DT,
  formValues: FormValues,
  organizationId: Id
): Promise<CustomPlanOrPlanGroupCreateResponse<DT>> => {
  const createdPlanOrPlanGroup = await create({
    data: formValues,
    dataType,
    pathParams: { organizationId },
  });

  // Create the account plan (plan attachment) referencing the newly-created plan/plan group.
  try {
    const isPlanCreate = dataType === DataType.Plan;
    const accountPlan: UnsavedEntity<AccountPlan> = {
      ...(isPlanCreate
        ? { planId: createdPlanOrPlanGroup.id }
        : { planGroupId: createdPlanOrPlanGroup.id }),
      accountId: formValues.accountId,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      contractId: formValues.contractId,
      childBillingMode: formValues.childBillingMode,
      billEpoch: formValues.billEpoch,
    };

    const createdAccountPlan = await create({
      data: accountPlan,
      dataType: DataType.AccountPlan,
      pathParams: { organizationId },
    });
    return { createdAccountPlan, createdPlanOrPlanGroup };
  } catch (error) {
    // Delete the plan/plan group so it's not left hanging.
    await del({
      dataType,
      id: createdPlanOrPlanGroup.id,
      pathParams: { organizationId },
    });
    throw error;
  }
};

const useCustomPlanCreate = <
  DT extends CustomPlanDataType,
  FormValues extends CustomPlanFormValues
>(
  dataType: DT
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentOrgId: organizationId } = useOrg();
  const { basePath, onCancel } = useCrudContext<AccountPlan>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const {
    error,
    isPending: isSaving,
    mutate: customPlanCreate,
  } = useMutation({
    mutationFn: async (data: FormValues) =>
      createCustomPlanOrPlanGroup(dataType, data, organizationId),
    onSuccess: ({ createdAccountPlan, createdPlanOrPlanGroup }) => {
      dispatch(dataCreated(dataType, [createdPlanOrPlanGroup]));
      dispatch(dataCreated(DataType.AccountPlan, [createdAccountPlan]));
      queryClient.invalidateQueries({ queryKey: [dataType] });
      queryClient.invalidateQueries({ queryKey: [DataType.AccountPlan] });
      navigate(returnPath.replace(':id', createdAccountPlan.id));
    },
  });

  const onSave = useCallback(
    (data: FormValues) => {
      customPlanCreate(data);
    },
    [customPlanCreate]
  );

  return {
    error,
    isSaving,
    onCancel,
    onSave,
  };
};

export default useCustomPlanCreate;
