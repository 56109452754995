import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';

import { useFormControl } from '@chakra-ui/react';
import { createFilter, GroupBase } from 'react-select';
import BaseCreatableSelect, { CreatableProps } from 'react-select/creatable';

import { SelectBaseProps, Option } from '../types';
import useReactSelectStyles from '../../../../theme/hooks/useReactSelectStyles';
import useSelectKeyDown from '../../../../hooks/useSelectKeyDown';
import { isOptionNotOptionGroup } from '../utils';
import { OptionWithSecondaryLabel } from '../OptionWithSecondaryLabel';

export interface CreatableSelectProps<T extends string = string>
  extends SelectBaseProps<T>,
    Pick<
      CreatableProps<Option<T>, false, GroupBase<Option<T>>>,
      'inputId' | 'menuPlacement' | 'placeholder'
    > {}

const EmptyComponent: React.FC = () => null;

export function CreatableSelect<T extends string = string>({
  options,
  value,
  onChange,
  isDisabled,
  isLoading,
  isSearchable,
  inputId,
  menuPlacement,
  placeholder,
  searchMatchFrom,
}: PropsWithChildren<CreatableSelectProps<T>>): ReactElement<any, any> {
  const { onMenuOpen, onMenuClose, onSelectKeyDown } = useSelectKeyDown();
  const { styles, theme } = useReactSelectStyles<Option<T>>();

  // Get the ID from the Chakra `FormControl` if there is one, so that
  // the label and form field are connected.
  const { id } = useFormControl({});

  const filterOption = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: searchMatchFrom,
        stringify: (option) => option.label,
        trim: true,
      }),
    [searchMatchFrom]
  );

  const selectedOption = useMemo(() => {
    if (!value) {
      // ReactSelect expects `null` for no selection, rather than `undefined`.
      return null;
    }

    // Can't use .find here since we might have options that are "buried"
    // inside of a group and we want to return a single Option, not an
    // OptionGroup
    let matchingOption: Option<T> | undefined;
    options.forEach((optionOrGroup) => {
      if (matchingOption) {
        return;
      }

      if (isOptionNotOptionGroup(optionOrGroup)) {
        if (optionOrGroup.value === value) {
          matchingOption = optionOrGroup;
        }
      } else {
        matchingOption = optionOrGroup.options.find(
          (option) => option.value === value
        );
      }
    });

    return matchingOption ?? { value, label: value };
  }, [options, value]);

  const handleChange = useCallback(
    (newOption: Option<T> | null) => {
      onChange(newOption ? newOption.value : null);
    },
    [onChange]
  );

  const hasOptions = options.length > 0;
  const components = useMemo(
    () => ({
      Option: OptionWithSecondaryLabel,
      ...(hasOptions
        ? {}
        : {
            DropdownIndicator: EmptyComponent,
            IndicatorSeparator: EmptyComponent,
          }),
    }),
    [hasOptions]
  );

  return (
    <BaseCreatableSelect<Option<T>, false>
      options={options}
      value={selectedOption}
      onChange={handleChange}
      onKeyDown={onSelectKeyDown}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      components={components}
      formatCreateLabel={(input: string) => `Add '${input}'`}
      noOptionsMessage={() => null}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={isSearchable}
      inputId={inputId ?? id}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      styles={styles}
      theme={theme}
      filterOption={filterOption}
    />
  );
}
