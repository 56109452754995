import Big from 'big.js';

/**
 * Calculates the total of commitment fees, rounding each fee to the specified number of decimal places.
 * This function uses the `big.js` library to avoid JavaScript floating point precision errors.
 *
 * @param commitmentFeeAmounts - An array of commitment fee amounts.
 * @param decimalPlaces - The number of decimal places to round each fee to. Defaults to 2.
 * @returns The total of the commitment fees, rounded to the specified number of decimal places.
 */
export const getCommitmentFeesTotal = (
  commitmentFeeAmounts: Array<number>,
  decimalPlaces: number = 2
): number =>
  commitmentFeeAmounts
    .reduce(
      (total, amount) => total.plus(new Big(amount).round(decimalPlaces)),
      new Big(0)
    )
    .round(decimalPlaces)
    .toNumber();
