import i18next from 'i18next';
import Big from 'big.js';
import orderBy from 'lodash/orderBy';

import {
  Bill,
  BillBandUsage,
  BillConfig,
  BillLineItem,
  CurrencyCode,
  Frequency,
  Id,
} from '@m3ter-com/m3ter-api';
import { addOrdinalSuffix } from '@m3ter-com/console-core/utils';

import { uniq } from './array';

export const OTHER_LINE_ITEMS = 'other-line-items';

/**
 * Totals the line item converted sub totals to get a bill total.
 * It rounds each line item to avoid cases like this, where if there
 * are 2 line items at $0.004 the bill would show as
 *
 *   Line Item 1    $0.00
 *   Line Item 2    $0.00
 *   Total          $0.01  (rounded from $0.008)
 */
export const getBillTotal = (bill: Bill, decimalPlaces: number = 2): number =>
  bill.lineItems
    .reduce(
      (total, lineItem) =>
        total.plus(new Big(lineItem.convertedSubtotal).round(decimalPlaces)),
      new Big(0)
    )
    .round(decimalPlaces)
    .toNumber();

/**
 * Retrieves a unique list of bill line item identifiers from an array of bills.
 *
 * This function processes each bill and its line items to extract unique identifiers.
 * The identifiers can be `productId`, `planGroupId`, `commitmentId`, or a default value
 * 'other-line-items' if none of these identifiers are present.
 *
 * The `productId` is the primary identifier for grouping (group by product). It can also serve as the
 * `accountingProductId` in certain scenarios, such as standing charge or minimum spend account product
 * on a plan group. In such cases, the line item for the associated plan group will have both a `productId`
 * (accounting product) and a `planGroupId`. In this case, the plan group identifier should be included in
 * the set, rather than the `productId`, to ensure proper grouping, e.g. within the plan group (group by
 * `planGroupId`).
 */
export const getUniqueBillLineItemIds = (bills: Array<Bill>): Array<Id> =>
  uniq(
    bills.flatMap((bill) =>
      bill.lineItems.flatMap((lineItem) => {
        const ids = new Set<Id>();
        if (lineItem.productId) {
          if (!lineItem.planGroupId) {
            ids.add(lineItem.productId);
          } else {
            ids.add(lineItem.planGroupId);
          }
        } else if (lineItem.commitmentId) {
          ids.add(lineItem.commitmentId);
        } else if (lineItem.planGroupId) {
          ids.add(lineItem.planGroupId);
        } else {
          ids.add(OTHER_LINE_ITEMS);
        }
        return Array.from(ids);
      })
    )
  );

/**
 * Takes a set of bills, returns an array of tuples where the keys are unique
 * ids from that set of bills. The values are duplicates of those bills, with
 * any line items not related to the current id removed.
 */
export const splitBillsByIds = (
  bills: Array<Bill>
): Array<[string, Array<Bill>]> =>
  /**
   * Get the unique ids from the bill line items these can be the product/commitment/plan group ids.
   * If none of these ids are on the bill line items we will group the remaining items together under
   * the id / key 'other-line-items' (these are most likey balance draw downs but we can't be sure).
   */
  orderBy(
    getUniqueBillLineItemIds(bills).map((id) => {
      const billDuplicates = bills.map((bill) => {
        const relevantLineItems = bill.lineItems.filter(
          (lineItem) =>
            // group line items by product id; if a plan group id is present, group
            // them accordingly within the plan group. This ensures no duplicate line
            // items appear in the bill details if both id are present on the line item.
            (lineItem.productId === id && !lineItem.planGroupId) ||
            // grouped line items by commitment id (commitment consumed/fee).
            lineItem.commitmentId === id ||
            // grouped line items plan group id.
            lineItem.planGroupId === id ||
            // group everything else without an id - these are most likey blances but we can't be sure.
            (id === OTHER_LINE_ITEMS &&
              !lineItem.productId &&
              !lineItem.commitmentId &&
              !lineItem.planGroupId)
        );

        // Order the line items by aggregationId, compoundAggregationId, counterId so they are grouped in the array.
        const orderedRelevantLineItems = orderBy(relevantLineItems, [
          (lineItem) =>
            lineItem.aggregationId ||
            lineItem.compoundAggregationId ||
            lineItem.counterId,
        ]);

        return { ...bill, lineItems: orderedRelevantLineItems };
      });

      return [id, billDuplicates];
    }),
    /**
     * Sort bills by product name, bills with an empty string as the product name are moved
     * to the end of the array. This is so the grouped product bill line items are sorted
     * alphabetically and placed before other line items groups without a product, e.g. plan
     * group line items, or prepayment line items, these will be placed at the end.
     */
    ([_, bill]) => {
      /**
       * If we have line items on the bill and the first one has a product name
       * (can be an empty string) we use the product name as the sorting value.
       */
      if (bill[0].lineItems.length && bill[0].lineItems[0].productName) {
        return bill[0].lineItems[0].productName;
      }
      /**
       * Otherwise we return the default value as the unicode "non-character" so that
       * these group bill line items will be sorted last.
       */
      return '\uffff';
    }
  );

/**
 * Checks a bill's locked property and, optionally, the
 * global lock date set on the org's bill config, if one exists.
 */
export const isBillLocked = (bill: Bill, billConfig?: BillConfig) => {
  if (!billConfig?.billLockDate) return bill.locked;
  const globalLockDate = new Date(billConfig.billLockDate);
  const billEndDate = new Date(bill.endDate);

  return globalLockDate >= billEndDate || bill.locked;
};

/**
 * Gets the total number of units for a line item. Defaults to `units` if present, then
 * if there is usage breakdown this is the total of all bands, otherwise it's the quantity.
 */
export const getLineItemUnits = (lineItem: BillLineItem): number =>
  lineItem.units ??
  (lineItem.usagePerPricingBand
    ? lineItem.usagePerPricingBand.reduce((total, band) => {
        return total + band.bandUnits;
      }, 0)
    : lineItem.quantity);

/**
 * Returns a summary of the unit prices within an array of usage bands.
 * If there is one unit price band it returns a single formatted value.
 * If there are multiple unit price bands it returns a min-max formatted range.
 * If there are no unit price bands it returns an empty string.
 */
export const getUnitPriceSummary = (
  usagePerPriceBand: Array<BillBandUsage>,
  currency: CurrencyCode,
  formatter: (
    value: number,
    currency: CurrencyCode,
    precise?: boolean
  ) => string
): string => {
  const unitPrices = usagePerPriceBand
    .filter((band) => !band.fixedPrice)
    .map((band) => band.unitPrice)
    .sort((a, b) => a - b);

  if (unitPrices.length === 0) {
    return '';
  }
  if (unitPrices.length === 1) {
    return formatter(unitPrices[0], currency, true);
  }
  // Return a min–max range.
  return `${formatter(unitPrices[0], currency, true)}–${formatter(
    unitPrices[unitPrices.length - 1],
    currency,
    true
  )}`;
};

export const getFrequencyDescription = (
  count: number,
  frequency: Frequency
): string => {
  return count === 1
    ? i18next.t('common:everyPeriod', {
        period: i18next.t(`common:frequencyPeriods.singular.${frequency}`),
      })
    : i18next.t('common:everyCountPeriod', {
        count,
        period: i18next.t(`common:frequencyPeriods.plural.${frequency}`),
      });
};

export const getIntervalAndOffsetDescription = (
  interval: number,
  offset: number
) => {
  if (offset === 0) {
    return interval === 1
      ? i18next.t('features:billing.everyBill')
      : i18next.t('features:billing.everyIntervalBills', { interval });
  }

  const start = addOrdinalSuffix(offset + 1); // Offset starts at 0
  return interval === 1
    ? i18next.t('features:billing.everyBillStarting', { start })
    : i18next.t('features:billing.everyIntervalBillsStarting', {
        interval,
        start,
      });
};

export const getBillInAdvanceDescription = (inAdvance?: boolean) =>
  inAdvance ? i18next.t('common:inAdvance') : i18next.t('common:inArrears');

export const getReference = (bill: Bill) =>
  bill.sequentialInvoiceNumber ?? `INV-${bill.id.slice(-4)}`;
