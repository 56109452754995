import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  create,
  createServiceUserCredential,
  DataType,
  Id,
  ServiceUser,
  ServiceUserCredential,
  updateEntityPermissionPolicies,
} from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import { useCrudContext } from '@/components/common/crud/CrudContext';
import { ServiceUserCreateFormValues } from '@/components/features/access/service-user/ServiceUserCreateForm';
import { useServiceUserCredentialsContext } from '@/components/features/access/service-user/ServiceUserCredentialsContext';
import useOrg from '@/hooks/data/crud/useOrg';
import useQueryString from '@/hooks/navigation/useQueryString';
import { dataCreated } from '@/store/data/data';

interface ServiceUserCreateResponse {
  createdServiceUser: ServiceUser;
  serviceUserCredentials?: ServiceUserCredential;
}

export const createServiceUserWithPermissionPolicies = async (
  formValues: ServiceUserCreateFormValues,
  organizationId: Id
): Promise<ServiceUserCreateResponse> => {
  const { permissionPolicyIds, generateAccessKey, ...serviceUser } = formValues;

  const createdServiceUser = await create({
    data: serviceUser,
    dataType: DataType.ServiceUser,
    pathParams: { organizationId },
  });

  let serviceUserCredentials: ServiceUserCredential | undefined;
  if (generateAccessKey) {
    serviceUserCredentials = await createServiceUserCredential(
      organizationId,
      createdServiceUser.id
    );
  }

  if (permissionPolicyIds.length > 0) {
    await updateEntityPermissionPolicies(
      'addtoserviceuser',
      organizationId,
      permissionPolicyIds,
      { principalId: createdServiceUser.id }
    );
  }

  return { createdServiceUser, serviceUserCredentials };
};

const useServiceUserCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentOrgId: organizationId } = useOrg();
  const { setNewServiceUserCredentials } = useServiceUserCredentialsContext();

  const { basePath, onCancel } = useCrudContext<ServiceUser>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const {
    error,
    isPending: isSaving,
    mutate: serviceUserCreate,
  } = useMutation({
    mutationFn: async (data: ServiceUserCreateFormValues) =>
      createServiceUserWithPermissionPolicies(data, organizationId),
    onSuccess: ({ createdServiceUser, serviceUserCredentials }) => {
      dispatch(dataCreated(DataType.ServiceUser, [createdServiceUser]));
      setNewServiceUserCredentials(serviceUserCredentials);
      queryClient.invalidateQueries({ queryKey: [DataType.ServiceUser] });
      navigate(returnPath.replace(':id', createdServiceUser.id));
    },
  });

  const onSave = useCallback(
    (data: ServiceUserCreateFormValues) => {
      serviceUserCreate(data);
    },
    [serviceUserCreate]
  );

  return {
    error,
    isSaving,
    onCancel,
    onSave,
  };
};

export default useServiceUserCreate;
