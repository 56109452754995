import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { Contract, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';
import { Alert, AlertLink } from '@m3ter-com/ui-components';

import type { TFunction } from 'i18next';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export interface AccountContractFieldProps {
  name: string;
  contract?: Contract;
}

export const AccountContractField: React.FC<AccountContractFieldProps> = ({
  name,
  contract,
}) => {
  const { t } = useTranslation();
  const accountId = useWatch({ name: 'accountId' });
  const accountParams = useMemo(() => ({ accountId }), [accountId]);
  const isContractFromParentAccount =
    contract && accountId !== contract?.accountId;

  return (
    <FormField
      name={name}
      label={t('forms:labels.contract')}
      control={FormEntitySelect as DataTypeFormEntitySelect<DataType.Contract>}
      isClearable
      dataType={DataType.Contract}
      params={accountParams}
      accessor="name"
      detailAccessor="code"
      alert={
        isContractFromParentAccount && (
          <Alert status="info">
            <Trans
              t={t as TFunction}
              i18nKey="features:account.parentAccountContractInfo"
              values={{ entityName: contract.name }}
              components={{
                contractDetailsLink: (
                  <AlertLink
                    as={CrudDetailsLink}
                    dataType={DataType.Contract}
                    id={contract.id}
                  />
                ),
              }}
            />
          </Alert>
        )
      }
    />
  );
};
