import React from 'react';

import { chakra } from '@chakra-ui/react';

import { NamedRoute } from '@/types/routes';

const RoadmapRoute: React.FC = () => (
  <chakra.iframe
    position="fixed"
    width="100%"
    height="100%"
    src="https://portal.productboard.com/x9hezzt3xhhpcytauyccbmem"
  />
);

export default (): NamedRoute => ({
  path: 'roadmap',
  name: 'roadmap',
  element: <RoadmapRoute />,
});
