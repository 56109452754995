import React, { useMemo } from 'react';

import { VStack } from '@chakra-ui/react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { XIcon } from 'lucide-react';

import { CommitmentFeeDate, CurrencyCode } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, IconButton } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormCurrencyInput,
  FormField,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
} from '@m3ter-com/console-core/components';

import useCurrencies from '@/hooks/util/useCurrencies';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import { getCommitmentFeesTotal } from '@/util/commitments';

export interface CommitmentFeeDatesFieldProps {
  name: string;
  expectedTotal: number;
  currency?: CurrencyCode;
}

export const CommitmentFeeDatesField: React.FC<
  CommitmentFeeDatesFieldProps
> = ({ name, expectedTotal, currency }) => {
  const { t } = useTranslation();
  const { formatCurrency, getMaxDecimalPlaces } = useCurrencies();
  const { control } = useFormContext();

  const values: Array<Partial<CommitmentFeeDate>> | undefined = useWatch({
    name,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const totalAmount = useMemo(() => {
    if (!values) {
      return 0;
    }

    const commitmentFeeAmounts = values.map(({ amount = 0 }) => amount);
    const maxDecimalPlaces = currency ? getMaxDecimalPlaces(currency) : 2;
    return getCommitmentFeesTotal(commitmentFeeAmounts, maxDecimalPlaces);
  }, [currency, getMaxDecimalPlaces, values]);

  return (
    <React.Fragment>
      {totalAmount !== expectedTotal && (
        <Alert status="warning">
          {t('features:commitments.totalShouldEqual', {
            amount: formatCurrency(expectedTotal, currency ?? ''),
          })}
        </Alert>
      )}
      <VStack width="100%" alignItems="stretch" spacing={4}>
        <FormMultiRowWrapper
          hasFields={fields.length > 0}
          emptyContentMessage={t('features:commitments.noFeeDates')}
        >
          {fields.map((field, index) => {
            return (
              <FormMultiRowGridWrapper key={field.id} columnCount={2}>
                <FormField
                  isRequired
                  label={t('forms:labels.date')}
                  name={`${name}.${index}.date`}
                  control={FormDatePicker}
                />
                <FormField
                  isRequired
                  label={t('forms:labels.amount')}
                  name={`${name}.${index}.amount`}
                  control={FormCurrencyInput}
                  code={currency ?? ''}
                />
                <FormField
                  isRequired
                  label={t('forms:labels.servicePeriodStart')}
                  name={`${name}.${index}.servicePeriodStartDate`}
                  control={FormDatePicker}
                />
                <FormField
                  isRequired
                  label={t('forms:labels.servicePeriodEnd')}
                  name={`${name}.${index}.servicePeriodEndDate`}
                  control={FormDatePicker}
                />
                <IconButton
                  aria-label={t('common:remove')}
                  icon={<XIcon />}
                  onClick={() => remove(index)}
                  gridRow="1"
                  gridColumn="3"
                  mt={8}
                />
              </FormMultiRowGridWrapper>
            );
          })}
        </FormMultiRowWrapper>
        <FormAddRowButton
          onAdd={() => {
            append({});
          }}
        />
      </VStack>
    </React.Fragment>
  );
};
