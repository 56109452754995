import React, { useCallback, useEffect, useState } from 'react';

import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Text,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import { Input } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormField,
  FormFieldset,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
} from '@m3ter-com/console-core/components';

import {
  formatParameterLabel,
  IntegrationConfigParameterFieldProps,
} from './shared';

type IntegrationConfigFieldMappingParameter = Record<string, string>;

interface IntegrationConfigFieldMappingParameterEditorRowProps {
  index: number;
  keyValue: string;
  valueValue: string;
  onKeyChange: (newKey: string) => void;
  onRemove?: () => void;
  onValueChange: (newValue: string) => void;
}

interface IntegrationConfigFieldMappingParameterEditorProps {
  name: string;
  value?: IntegrationConfigFieldMappingParameter;
  onChange: (newValue: IntegrationConfigFieldMappingParameter) => void;
}

type FormIntegrationConfigFieldMappingParameterEditorProps = Omit<
  IntegrationConfigFieldMappingParameterEditorProps,
  'value' | 'onChange'
>;

interface IntegrationConfigFieldMappingParameterEditorInternalRow {
  id: string;
  key: string;
  value: string;
}

const IntegrationConfigFieldMappingParameterEditorRow: React.FC<
  IntegrationConfigFieldMappingParameterEditorRowProps
> = ({ index, keyValue, onKeyChange, onRemove, onValueChange, valueValue }) => {
  const { t } = useTranslation();

  const handleKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onKeyChange(event.target.value);
    },
    [onKeyChange]
  );

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange]
  );

  const keyLabel = <FormLabel>{t('forms:labels.m3terField')}</FormLabel>;
  const valueLabel = <FormLabel>{t('forms:labels.externalField')}</FormLabel>;

  return (
    <React.Fragment>
      <FormControl>
        {index > 0 ? <VisuallyHidden>{keyLabel}</VisuallyHidden> : keyLabel}
        <Input value={keyValue} onChange={handleKeyChange} />
      </FormControl>
      <FormControl>
        {index > 0 ? <VisuallyHidden>{valueLabel}</VisuallyHidden> : valueLabel}
        <Input value={valueValue} onChange={handleValueChange} />
      </FormControl>
      <div>
        {onRemove && (
          <IconButton
            aria-label={t('common:remove')}
            icon={<XIcon />}
            onClick={onRemove}
            mt={index === 0 ? 8 : 0}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const EMPTY_VALUE: IntegrationConfigFieldMappingParameter = {};

const IntegrationConfigFieldMappingParameterEditor: React.FC<
  IntegrationConfigFieldMappingParameterEditorProps
> = ({ name, value = EMPTY_VALUE, onChange }) => {
  const { isInvalid, message } = useFormError(name);

  // We need to convert the object value into an array and maintain it in state
  // so that we can guarantee order and have consistent (React) keys.
  // We use a uuid for each row to prevent React mounting new components on key change
  // or keeping old components on adding/removing rows.
  const [rows, setRows] = useState<
    Array<IntegrationConfigFieldMappingParameterEditorInternalRow>
  >(() =>
    Object.entries(value).map(([rowKey, rowValue]) => ({
      id: v4(),
      key: rowKey,
      value: rowValue,
    }))
  );

  const onKeyChange = useCallback((rowId: string, newKey: string) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === rowId ? { ...row, key: newKey } : row
      )
    );
  }, []);

  const onValueChange = useCallback((rowId: string, newValue: string) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === rowId ? { ...row, value: newValue } : row
      )
    );
  }, []);

  const onRemoveRow = useCallback((rowId: string) => {
    setRows((currentRows) => currentRows.filter((row) => row.id !== rowId));
  }, []);

  const onAdd = useCallback(() => {
    setRows((currentRows) => [
      ...currentRows,
      { id: v4(), key: '', value: '' },
    ]);
  }, []);

  // When rows change we can re-build the object from the rows and call onChange.
  useEffect(() => {
    const updated = rows.reduce<IntegrationConfigFieldMappingParameter>(
      (acc, row) => {
        acc[row.key] = row.value;
        return acc;
      },
      {}
    );

    if (!isEqual(updated, value)) {
      onChange(updated);
    }
  }, [onChange, rows, value]);

  return (
    <VStack width="100%" alignItems="stretch" spacing={4}>
      <FormControl id={name} isInvalid={!!isInvalid}>
        <FormMultiRowWrapper hasFields={rows.length > 0}>
          {rows.map((row, index) => {
            const { id: rowId, key: rowKey, value: rowValue } = row;

            return (
              <FormMultiRowGridWrapper key={rowId} columnCount={2}>
                <IntegrationConfigFieldMappingParameterEditorRow
                  index={index}
                  keyValue={rowKey}
                  onKeyChange={(newKey) => onKeyChange(rowId, newKey)}
                  onRemove={() => onRemoveRow(rowId)}
                  onValueChange={(newValue) => onValueChange(rowId, newValue)}
                  valueValue={rowValue}
                />
              </FormMultiRowGridWrapper>
            );
          })}
        </FormMultiRowWrapper>
        {isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      </FormControl>
      <FormAddRowButton onAdd={onAdd} />
    </VStack>
  );
};

const FormIntegrationConfigFieldMappingParameterEditor: React.FC<
  FormIntegrationConfigFieldMappingParameterEditorProps
> = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <IntegrationConfigFieldMappingParameterEditor
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export const IntegrationConfigFieldMappingParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  return (
    <FormFieldset legend={formatParameterLabel(schema.name)} width="100%">
      {schema.description && (
        <Text mb={2} variant="annotation">
          {schema.description}
        </Text>
      )}
      <FormField
        hideLabel
        name={name}
        label=""
        control={FormIntegrationConfigFieldMappingParameterEditor}
      />
    </FormFieldset>
  );
};
