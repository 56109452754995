import { useCallback, useMemo } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { AccountPlan, DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useEntityUpdateMutation from '@/hooks/data/useEntityUpdateMutation';
import { NotificationOptions } from '@/hooks/util/useNotification';
import { dataTypeRetrieveQuery } from '@/queries/crud';

const useAccountPlanContracts = (accountPlan: AccountPlan) => {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const {
    isOpen: isContractsModalOpen,
    onOpen: onOpenContractsModal,
    onClose: onCloseContractsModal,
  } = useDisclosure();

  const {
    isLoading: isLoadingContract,
    error: errorLoadingContract,
    data: contract,
  } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.Contract,
        id: accountPlan.contractId,
        pathParams,
      },
      { enabled: !!accountPlan.contractId }
    )
  );

  const {
    error: errorAddingContract,
    isSaving: isAddingContract,
    updateEntity,
  } = useEntityUpdateMutation(DataType.AccountPlan);

  const contractAddedToPlanSuccessNotification = useMemo<NotificationOptions>(
    () => ({
      description: t('notifications:contractAddedToAccountPlanSuccess'),
      duration: 5000,
      status: 'success',
    }),
    [t]
  );
  const contractAddedToPlanFailureNotification = useMemo<NotificationOptions>(
    () => ({
      description: t('notifications:contractAddedToAccountPlanFailure'),
      status: 'error',
    }),
    [t]
  );

  const onAddContractToAccountPlan = useCallback(
    (contractId: Id | null) => {
      if (contractId && contractId !== accountPlan.contractId) {
        updateEntity({
          newEntity: { ...accountPlan, contractId },
          failureNotification: contractAddedToPlanFailureNotification,
          successNotification: contractAddedToPlanSuccessNotification,
        });
      }
    },
    [
      accountPlan,
      contractAddedToPlanFailureNotification,
      contractAddedToPlanSuccessNotification,
      updateEntity,
    ]
  );

  return {
    contract,
    errorAddingContract,
    errorLoadingContract,
    isAddingContract,
    isContractsModalOpen,
    isLoadingContract,
    onAddContractToAccountPlan,
    onCloseContractsModal,
    onOpenContractsModal,
  };
};

export default useAccountPlanContracts;
