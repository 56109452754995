import React, { PropsWithChildren } from 'react';

import {
  Box,
  BoxProps,
  Code,
  Heading,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';

import { DateTimeISOString } from '@m3ter-com/m3ter-api';
import { Alert } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { Submission } from '@/hooks/features/meters/useIngest';
import useDateFormatter from '@/hooks/util/useDateFormatter';

interface IngestSubmissionLogProps extends BoxProps {
  submissions: Array<Submission>;
}

interface LogEntryProps {
  type?: 'neutral' | 'error' | 'success';
  timestamp: DateTimeISOString;
}

const LogEntry: React.FC<PropsWithChildren<LogEntryProps>> = ({
  type = 'neutral',
  timestamp,
  children,
}) => {
  const { toLongDateTime } = useDateFormatter();

  let colorScheme;
  if (type === 'error') {
    colorScheme = 'red';
  } else if (type === 'success') {
    colorScheme = 'green';
  }

  return (
    <React.Fragment>
      <Text fontSize="sm">{toLongDateTime(timestamp)}</Text>
      <Code
        as="pre"
        display="block"
        fontSize="xs"
        padding={2}
        mb={2}
        colorScheme={colorScheme}
      >
        {children}
      </Code>
    </React.Fragment>
  );
};

export const IngestSubmissionLog: React.FC<IngestSubmissionLogProps> = ({
  submissions,
  ...boxProps
}) => {
  const { t } = useTranslation();

  return (
    <Box overflowY="scroll" {...boxProps}>
      <Box maxHeight={0}>
        <Heading size="sm" mb={4}>
          {t('common:log')}
        </Heading>
        {submissions.length === 0 ? (
          <Alert status="info">
            {t('features:meters.usageLogDescription')}
          </Alert>
        ) : (
          <Stack divider={<StackDivider />}>
            {submissions.map((submission) => (
              <Box key={submission.id} data-testid="submission">
                {submission.responseTimestamp && (
                  <LogEntry
                    timestamp={submission.responseTimestamp}
                    type={submission.responseType}
                  >
                    {submission.response}
                  </LogEntry>
                )}
                <LogEntry timestamp={submission.requestTimestamp}>
                  {JSON.stringify(submission.body, null, 2)}
                </LogEntry>
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};
