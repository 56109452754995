import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  StrictEffect,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import i18next from 'i18next';
import { EventChannel, eventChannel, Task } from 'redux-saga';

import { update, DataType, User } from '@m3ter-com/m3ter-api';

import { ROOT_AUTH_PATH } from '@/routes/auth';
import { extractError } from '@/util/error';
import { IDP_IDENTIFIER_PREFIX } from '@/util/auth';
import { Context } from '@/store/rootSaga';
import { singletonUpdated } from '@/store/data/data';
import { retrieveSingletonData } from '@/store/data/data.saga';

import {
  acceptTerms,
  acceptTermsFailure,
  acceptTermsSuccess,
  completeNewPassword,
  CompleteNewPasswordAction,
  completeNewPasswordFailure,
  completeNewPasswordSuccess,
  forgotPassword,
  ForgotPasswordAction,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordSubmit,
  ForgotPasswordSubmitAction,
  forgotPasswordSubmitFailure,
  forgotPasswordSubmitSuccess,
  restoreSession,
  restoreSessionComplete,
  signIn,
  SignInAction,
  signOut,
  signInSuccess,
  signInFailure,
  signInPartialSuccess,
  signOutFailure,
  selectExpirationTime,
} from './auth';

export function* redirectToAuth(
  context: Context,
  authPath = 'sign-in'
): Generator<StrictEffect, void, any> {
  yield call(context.router.navigate, `/auth/${authPath}`, {
    replace: true,
  });
}

export function isM3terAdmin(session: CognitoUserSession) {
  try {
    const payload = session.getIdToken().decodePayload();
    const groups: Array<string> = payload['cognito:groups'] ?? [];
    return groups.includes('m3terAdmin') || groups.includes('m3terBasicAdmin');
  } catch (error) {
    return false;
  }
}

export function* restoreSessionSaga(
  context: Context
): Generator<StrictEffect, void, any> {
  try {
    const cognitoUser: CognitoUser = yield call([
      Auth,
      Auth.currentAuthenticatedUser,
    ]);
    const session: CognitoUserSession = yield call(
      [Auth, Auth.userSession],
      cognitoUser
    );

    if (session.isValid()) {
      const user: User = yield call(
        retrieveSingletonData,
        DataType.CurrentUser
      );
      const { exp: expiresAt } = session.getAccessToken().decodePayload();
      const m3terAdmin: boolean = yield call(isM3terAdmin, session);
      yield put(signInSuccess(user, expiresAt * 1000, m3terAdmin));
    }
  } catch {
    // If we have failed to restore the session and the user isn't trying to
    // access an auth page, redirect them to the auth page
    if (!window.location.pathname.startsWith(`/${ROOT_AUTH_PATH}`)) {
      yield call(redirectToAuth, context);
    }
  }

  yield put(restoreSessionComplete());
}

export function* signInSaga(
  context: Context,
  action: SignInAction
): Generator<StrictEffect, void, any> {
  const { email, password } = action.payload;

  try {
    // Regular sign-in
    if (email && password) {
      const cognitoUser: CognitoUser = yield call(
        [Auth, Auth.signIn],
        email,
        password
      );

      // If a new password is required as part of the sign-in flow, initiate a multi-step process by
      // redirecting the user to the 'complete-new-password' page. This signifies that the user, with
      // cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED', is still in a sign-in flow but not a
      // standard one, as additional steps are needed for them to complete the sign-in.
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Initiate a new password completion task by redirecting and dispatching a partial success action
        yield call(redirectToAuth, context, 'complete-new-password');
        yield put(signInPartialSuccess());

        // Wait for the user to complete the flow for creating their new password
        yield call(completeNewPasswordSaga, cognitoUser, context);
      } else {
        // If no new password is required, restore the session
        yield put(restoreSession());
      }
    } else if (email && !password) {
      // Federated sign-in using only email
      const emailParts = email.split('@');

      // Get the email domain and prefix it so we can replace the `indentity_provider`
      // query paramin the authorise call with the idp_identifier, e.g. the email domain.
      if (emailParts.length === 2) {
        yield call([Auth, Auth.federatedSignIn], {
          customProvider: `${IDP_IDENTIFIER_PREFIX}${emailParts[1]}`,
        });
      } else {
        const emailDomainErrorMessage = i18next.t(
          'features:auth.emailDomainError'
        );
        throw new Error(emailDomainErrorMessage);
      }
    }
  } catch (error) {
    yield put(signInFailure(extractError(error)));
  }
}

export function* forgotPasswordSaga(
  context: Context,
  action: ForgotPasswordAction
): Generator<StrictEffect, void, any> {
  const { email } = action.payload;
  try {
    yield call([Auth, Auth.forgotPassword], email);
    yield call(redirectToAuth, context, 'forgot-password-submit');
    yield put(forgotPasswordSuccess());
  } catch (error) {
    yield put(forgotPasswordFailure(extractError(error)));
  }
}

export function* forgotPasswordSubmitSaga(
  context: Context,
  action: ForgotPasswordSubmitAction
): Generator<StrictEffect, void, any> {
  const { email, code, newPassword } = action.payload;
  try {
    yield call([Auth, Auth.forgotPasswordSubmit], email, code, newPassword);
    yield call(redirectToAuth, context);
    yield put(forgotPasswordSubmitSuccess());
  } catch (error) {
    yield put(forgotPasswordSubmitFailure(extractError(error)));
  }
}

export function* completeNewPasswordSaga(
  cognitoUser: CognitoUser,
  context: Context
): Generator<StrictEffect, any, any> {
  while (true) {
    try {
      const action: CompleteNewPasswordAction = yield take(
        completeNewPassword.type
      );
      const { newPassword, name } = action.payload;
      yield call([Auth, Auth.completeNewPassword], cognitoUser, newPassword, {
        name,
      });
      yield call(redirectToAuth, context);
      yield put(completeNewPasswordSuccess());
      break;
    } catch (error) {
      yield put(completeNewPasswordFailure(extractError(error)));
    }
  }
}

export function* signOutSaga(): Generator<StrictEffect, void, any> {
  try {
    yield call([Auth, Auth.signOut]);
    yield call(
      [window.location, window.location.assign],
      window.location.origin
    );
  } catch (error) {
    yield put(signOutFailure(extractError(error)));
  }
}

export function createVisibilityChangeChannel(): EventChannel<Event> {
  return eventChannel<Event>((emitter) => {
    const handler = (event: Event) => {
      emitter(event);
    };

    document.addEventListener('visibilitychange', handler);
    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  });
}

export function* visibilityChangeSaga(): Generator<StrictEffect, void, any> {
  let lastTask: Task | undefined;
  let visibilityChangeChannel: EventChannel<Event> | undefined;

  try {
    lastTask = yield fork(invalidateSessionSaga);
    visibilityChangeChannel = yield call(createVisibilityChangeChannel);

    if (visibilityChangeChannel) {
      while (true) {
        yield take(visibilityChangeChannel);
        if (lastTask) {
          yield cancel(lastTask);
        }
        lastTask = yield fork(invalidateSessionSaga);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    if (visibilityChangeChannel) {
      yield call(visibilityChangeChannel.close);
    }
  }
}

export function* invalidateSessionSaga(): Generator<StrictEffect, void, any> {
  const expirationTime: number | undefined = yield select(selectExpirationTime);

  try {
    if (expirationTime !== undefined) {
      const currentTime = new Date(Date.now()).getTime();
      const timeUntilExpiration = expirationTime - currentTime;
      yield delay(timeUntilExpiration);
      yield put(signOut());
    }
  } catch (error) {
    // No op: user not authenticated
  }
}

export function* acceptTermsSaga(): Generator<StrictEffect, void, any> {
  try {
    const updatedUser = yield call(update, {
      dataType: DataType.CurrentUser,
      actionName: 'acceptTerms',
    });
    yield put(singletonUpdated(DataType.CurrentUser, updatedUser));
    yield put(acceptTermsSuccess());
  } catch (error) {
    yield put(acceptTermsFailure(extractError(error)));
  }
}

export default function* authSaga(context: Context) {
  yield takeEvery(acceptTerms.type, acceptTermsSaga);
  yield takeLatest(signIn.type, signInSaga, context);
  yield takeLatest(signOut.type, signOutSaga);
  yield takeLatest(forgotPassword.type, forgotPasswordSaga, context);
  yield takeLatest(
    forgotPasswordSubmit.type,
    forgotPasswordSubmitSaga,
    context
  );
  yield takeLatest(restoreSession.type, restoreSessionSaga, context);
  yield takeLatest(signInSuccess.type, visibilityChangeSaga);
}
