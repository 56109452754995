import React from 'react';

import { useSelector } from 'react-redux';

import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutMain,
  LoadingScreen,
} from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import {
  selectBootstrapFailureReason,
  selectIsBootstrapping,
} from '@/store/app/bootstrap/bootstrap';
import { OrgSelectionList } from '@/components/common/navigation/OrgSelectionList';
import { ConsoleHeader } from '@/components/common/navigation/ConsoleHeader';
import { BootstrapFailureContent } from '@/components/features/organization/BootstrapFailureContent/BootstrapFailureContent';

export const RootIndexRoute: React.FC = () => {
  const isAppBootstrapping = useSelector(selectIsBootstrapping);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason);

  if (isAppBootstrapping) {
    setAuthRedirectPath('/');
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  return appBootstrapFailureReason ? (
    <BootstrapFailureContent />
  ) : (
    <AppLayout>
      <AppLayoutHeader>
        <ConsoleHeader showNavigation={false} showOrgSwitcher={false} />
      </AppLayoutHeader>
      <AppLayoutMain display="flex" alignItems="center">
        <OrgSelectionList />
      </AppLayoutMain>
    </AppLayout>
  );
};
