import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';

import { IntegrationConfigParameterSchema } from '@m3ter-com/m3ter-api';

export interface IntegrationConfigParameterFieldProps {
  name: string;
  schema: IntegrationConfigParameterSchema;
}

export const formatParameterLabel = (parameterName: string): string =>
  upperFirst(lowerCase(parameterName));
