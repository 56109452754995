import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: () => ({
    html: {
      scrollPaddingTop: '80px', // Header height (60px) plus some margin, applies when scrolling elements into view.
    },
    body: {
      fontFamily: 'DM Sans, sans-serif',
      overscrollBehavior: 'none',
      overflowY: 'scroll',
    },
    // Overrides for Userflow's placement until we go live with the new header and
    // can update the position in their theme instead.
    '.userflowjs-resource-center': {
      top: '5px !important',
      right: '16px !important',
      left: 'auto !important',
    },
  }),
};

export default styles;
