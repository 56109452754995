import { DataType, DataTypeToEntity, UnknownEntity } from '../../types';
import { uniq } from '../../util/array';

export enum RelationshipType {
  OneToMany = 'ONE_TO_MANY',
  OneToOne = 'ONE_TO_ONE',
}

interface RelationshipDefinition {
  foreignKey: string;
  dataType: DataType;
  type: RelationshipType;
}

type DataTypeRelationshipDefinition = Record<string, RelationshipDefinition>;

type DataTypeRelationshipDefinitions = {
  [K in DataType]?: DataTypeRelationshipDefinition;
};

const entityRelationships = {
  [DataType.AccountPlan]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
    plan: {
      foreignKey: 'planId',
      dataType: DataType.Plan,
      type: RelationshipType.OneToOne,
    },
    planGroup: {
      foreignKey: 'planGroupId',
      dataType: DataType.PlanGroup,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Aggregation]: {
    meter: {
      foreignKey: 'meterId',
      dataType: DataType.Meter,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Bill]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Commitment]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
    billingPlan: {
      foreignKey: 'billingPlanId',
      dataType: DataType.Plan,
      type: RelationshipType.OneToOne,
    },
    products: {
      foreignKey: 'productIds',
      dataType: DataType.Product,
      type: RelationshipType.OneToMany,
    },
    contract: {
      foreignKey: 'contractId',
      dataType: DataType.Contract,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Contract]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Counter]: {
    product: {
      foreignKey: 'productId',
      dataType: DataType.Product,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.CounterAdjustment]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
    itemCounter: {
      foreignKey: 'counterId',
      dataType: DataType.Counter,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.CounterPricing]: {
    itemCounter: {
      foreignKey: 'counterId',
      dataType: DataType.Counter,
      type: RelationshipType.OneToOne,
    },
    plan: {
      foreignKey: 'planId',
      dataType: DataType.Plan,
      type: RelationshipType.OneToOne,
    },
    planTemplate: {
      foreignKey: 'planTemplateId',
      dataType: DataType.PlanTemplate,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.ExportJob]: {
    exportSchedule: {
      foreignKey: 'scheduleId',
      dataType: DataType.ExportSchedule,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.ExportSchedule]: {
    exportDestinations: {
      foreignKey: 'destinationIds',
      dataType: DataType.ExportDestination,
      type: RelationshipType.OneToMany,
    },
    meters: {
      foreignKey: 'meterIds',
      dataType: DataType.Meter,
      type: RelationshipType.OneToMany,
    },
    accounts: {
      foreignKey: 'accountIds',
      dataType: DataType.Account,
      type: RelationshipType.OneToMany,
    },
  },
  [DataType.Meter]: {
    product: {
      foreignKey: 'productId',
      dataType: DataType.Product,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.M3terEvent]: {
    account: {
      foreignKey: 'm3terEvent.eventData.accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.OrganizationAdmin]: {
    customer: {
      foreignKey: 'customerId',
      dataType: DataType.Customer,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Plan]: {
    account: {
      foreignKey: 'accountId',
      dataType: DataType.Account,
      type: RelationshipType.OneToOne,
    },
    planTemplate: {
      foreignKey: 'planTemplateId',
      dataType: DataType.PlanTemplate,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.PlanGroupLink]: {
    plan: {
      foreignKey: 'planId',
      dataType: DataType.Plan,
      type: RelationshipType.OneToOne,
    },
    planGroup: {
      foreignKey: 'planGroupId',
      dataType: DataType.PlanGroup,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.PlanTemplate]: {
    product: {
      foreignKey: 'productId',
      dataType: DataType.Product,
      type: RelationshipType.OneToOne,
    },
  },
  [DataType.Pricing]: {
    plan: {
      foreignKey: 'planId',
      dataType: DataType.Plan,
      type: RelationshipType.OneToOne,
    },
    planTemplate: {
      foreignKey: 'planTemplateId',
      dataType: DataType.PlanTemplate,
      type: RelationshipType.OneToOne,
    },
    aggregation: {
      foreignKey: 'aggregationId',
      dataType: DataType.Aggregation,
      type: RelationshipType.OneToOne,
    },
    compoundAggregation: {
      foreignKey: 'compoundAggregationId',
      dataType: DataType.CompoundAggregation,
      type: RelationshipType.OneToOne,
    },
  },
} satisfies DataTypeRelationshipDefinitions;

type AvailableRelationships = typeof entityRelationships;

// For one-to-many relationships the data will be an array of entities.
// Otherwise it will just be one entity. Either way, the entity type is based
// on the `dataType` property from the `RelationshipDefinition`.
type EntityOrEntities<R extends RelationshipDefinition> =
  R['type'] extends RelationshipType.OneToMany
    ? Array<DataTypeToEntity[R['dataType']]>
    : DataTypeToEntity[R['dataType']];

// Any data type that is in `AvailableRelationships` will have optional
// properties for each key, with the type being based on the definition.
// We need to check it extends `DataTypeRelationshipDefinition` because
// otherwise TS can't guarantee it can be used in `EntityOrEntities`.
// For example, for `DataType.Aggregation` this would by typed as
// `{ meter?: Meter }`
type EntityRelationships<DT extends DataType> =
  DT extends keyof AvailableRelationships
    ? AvailableRelationships[DT] extends DataTypeRelationshipDefinition
      ? {
          [K in keyof AvailableRelationships[DT]]?: EntityOrEntities<
            AvailableRelationships[DT][K]
          >;
        }
      : {}
    : {};

// Combines the standard entity type (based on data type) with the related
// data. In cases where there aren't any relationships this just resolves
// to the entity type.
export type EntityWithRelationships<DT extends DataType> =
  DT extends keyof AvailableRelationships
    ? DataTypeToEntity[DT] & EntityRelationships<DT>
    : DataTypeToEntity[DT];

export const getRelationship = (
  sourceDataType: DataType,
  name: string
): RelationshipDefinition => {
  const relationship = (entityRelationships as DataTypeRelationshipDefinitions)[
    sourceDataType
  ]?.[name];

  if (!relationship) {
    throw new Error(`Unable to find relationship ${name} on ${sourceDataType}`);
  }

  return relationship;
};

// Allows access to relationships in nested objects using dot-notation.
export const getForeignKeyValue = (
  obj: { [key: string]: any },
  path: string
): any =>
  path.split('.').reduce((prevObj, key) => prevObj && prevObj[key], obj);

export const getRelatedIds = (
  data: Array<UnknownEntity>,
  foreignKey: string
) => {
  return uniq(
    data.map((item) => getForeignKeyValue(item, foreignKey)).flat() // Handle one-to-many (array of IDs) by flattening.
  ).filter(Boolean); // Remove undefined values, for optional relationshiops.
};

export const getEntityRelatedData = <DT extends DataType>(
  sourceDataType: DT,
  sourceEntity: DataTypeToEntity[DT],
  relationshipEntities: Record<string, Array<UnknownEntity>>
) => {
  const relatedData: Record<string, any> = {};

  Object.keys(relationshipEntities).forEach((relationshipName) => {
    const relationship = getRelationship(sourceDataType, relationshipName);

    if (relationship.type === RelationshipType.OneToOne) {
      const id = getForeignKeyValue(sourceEntity, relationship.foreignKey);
      // If we fail to find an ID for the related entity on the source entity, it could be
      // be an optional field set to null, so we can't assign a related entity.
      // If we get back an array of IDs from the source entity, something is wrong with our
      // relationships or the API, we shouldn't assign a related entity.
      if (!id || Array.isArray(id)) {
        relatedData[relationshipName] = undefined;
      } else {
        relatedData[relationshipName] = relationshipEntities[
          relationshipName
        ].find((item) => item.id === id);
      }
    }

    if (relationship.type === RelationshipType.OneToMany) {
      const ids = getForeignKeyValue(sourceEntity, relationship.foreignKey);
      // If we fail to find IDs for the related entities on the source entity, it could be
      // be an optional field set to null, so we can't assign related entities, but can assign
      // an empty array to make it easier for consumers of this data.
      // Same story if we get back an empty array of IDs.
      // If we get back something that isn't an array (e.g. a single string ID), something is
      // wrong with our relationships or the API, we shouldn't assign a related entity.
      if (!ids || !Array.isArray(ids) || ids.length === 0) {
        relatedData[relationshipName] = [];
      } else {
        relatedData[relationshipName] = relationshipEntities[
          relationshipName
        ].filter((item) => ids.includes(item.id));
      }
    }
  });

  return relatedData as EntityRelationships<DT>;
};
