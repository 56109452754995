import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import {
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { CircleHelpIcon, ExternalLinkIcon } from 'lucide-react';

import {
  BadgeIconButton,
  HeaderNavigationItem,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export const HeaderHelpMenu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Menu placement="bottom-end">
      <HeaderNavigationItem>
        <MenuButton
          as={BadgeIconButton}
          addTooltip
          aria-label={t('common:help')}
          icon={<CircleHelpIcon />}
          variant="header-nav"
        />
      </HeaderNavigationItem>
      <MenuList>
        <MenuItem
          as={Link}
          isExternal
          href="https://www.m3ter.com/docs"
          _hover={{ textDecoration: 'none ' }}
        >
          {t('common:documentation')} <Icon as={ExternalLinkIcon} ml={2} />
        </MenuItem>
        <MenuItem
          as={Link}
          isExternal
          href="https://m3ter.myfreshworks.com/login/auth/1662629775049?client_id=451980218021503405&amp;redirect_uri=https%3A%2F%2Fm3ter.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dm3ter.freshdesk.com"
        >
          {t('common:support')} <Icon as={ExternalLinkIcon} ml={2} />
        </MenuItem>
        <MenuItem as={Link} isExternal href="http://status.m3ter.com">
          {t('common:serviceStatus')} <Icon as={ExternalLinkIcon} ml={2} />
        </MenuItem>
        <MenuItem as={RouterLink} to="/roadmap" target="_blank">
          {t('common:productRoadmap')} <Icon as={ExternalLinkIcon} ml={2} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
